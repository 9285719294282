import { Container } from "@components/Container";
import { media, px2rem, responsive, styled } from "@styles";

export const TeaserContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  margin-top: 40px;

  ${media.lg.up} {
    margin-top: 60px;
  }
`;

export const ContentWrapper = styled(Container)`
  position: relative;
`;

export const Flex = styled.div`
  display: flex;
  flex-flow: column;
  gap: 30px;
  padding-bottom: 2rem;

  ${media.lg.up} {
    flex-flow: row;
    justify-content: start;
    align-items: start;
    gap: 2px;
    overflow: hidden;
    padding-bottom: 4rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 20px;

  ${media.lg.up} {
    display: flex;
  }

  h2 {
    font-size: ${px2rem(34)};
    margin-bottom: 20px;

    ${media.lg.up} {
      font-size: ${px2rem(40)};
      max-width: 460px;
    }
  }

  p {
    font-size: ${px2rem(18)};
    color: ${({ theme }) => theme.colors.dark};

    ${media.lg.up} {
      max-width: 422px;
    }
  }
`;

export const Images = styled.div`
  position: relative;
  overflow-x: hidden;
  margin: 2rem -1rem 2rem -1rem;
  align-items: center;

  ${media.lg.up} {
    margin-top: 40px;
    left: 2rem;
  }

  img {
    object-fit: contain;
  }
`;

export const Listing = styled.div`
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacing.mobileGutter};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 20px;
  width: 100%;

  ${media.xl.up} {
    padding: 0 0 ${({ theme }) => theme.spacing.gutter} 20px;
    grid-template-columns: repeat(auto-fill, 160px);
    row-gap: 30px;
    column-gap: 20px;
    grid-column: 1/3;
  }
`;

export const ListingItem = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: ${px2rem(14)};

  img {
    width: 185px;
  }

  ${media.lg.up} {
    img {
      width: auto;
      max-width: 100%;
    }
  }
`;

export const ListingTitle = styled.span`
  h3 {
    position: relative;
    ${responsive("font-size", px2rem(18), px2rem(20))}
    color: ${({ theme }) => theme.colors.black};
    white-space: nowrap;
    font-weight: 900;
    display: inline;

    &:after {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      background-color: #000000;
      bottom: -12px;
      left: 0;
    }
  }
`;

export const ListingText = styled.div`
  ${responsive("font-size", px2rem(12), px2rem(16))}
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.dark};
`;
