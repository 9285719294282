import { PrismicRichText } from "@prismicio/react";
import React from "react";

import { TeaserProps } from "../..";
import { Slider } from "./components";

import * as S from "./styles";

export const Teaser = ({ slice }: TeaserProps): JSX.Element => {
  const {
    primary: {
      header,
      description,
      usp1_title,
      usp1_text,
      usp2_title,
      usp2_text,
      usp3_title,
      usp3_text,
      usp4_title,
      usp4_text,
      usp5_title,
      usp5_text,
    },
    items,
  } = slice;

  const teaserPromotions = [
    {
      listingText: usp1_text,
      listingTitle: usp1_title,
    },
    {
      listingText: usp2_text,
      listingTitle: usp2_title,
    },
    {
      listingText: usp3_text,
      listingTitle: usp3_title,
    },
    {
      listingText: usp4_text,
      listingTitle: usp4_title,
    },
    {
      listingText: usp5_text,
      listingTitle: usp5_title,
    },
  ];

  const images = items.reduce((acc: string[], { teaser_image }) => {
    if (teaser_image?.url) {
      acc.push(teaser_image.url);
    }
    return acc;
  }, []);

  return (
    <S.TeaserContainer className="no-margin">
      <S.ContentWrapper>
        <S.Flex>
          <S.Content>
            <PrismicRichText field={header} />
            <PrismicRichText field={description} />
          </S.Content>

          <S.Listing>
            {teaserPromotions.length > 0 &&
              teaserPromotions.map(({ listingTitle, listingText }, index) => (
                <S.ListingItem key={index}>
                  <S.ListingTitle>
                    <PrismicRichText field={listingTitle} />
                  </S.ListingTitle>
                  <S.ListingText>
                    <PrismicRichText field={listingText} />
                  </S.ListingText>
                </S.ListingItem>
              ))}
          </S.Listing>
        </S.Flex>
      </S.ContentWrapper>
      {images.length > 0 && <Slider photos={images} />}
    </S.TeaserContainer>
  );
};

export default Teaser;
