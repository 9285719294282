import Image from "next/image";
import { keyframes } from "styled-components";

import { media, styled } from "@styles";

const mobileSlideWidth = "250px";
const desktopSlideWidth = "300px";
const animationSpeed = "60s";

const infiniteAutoplayAnimation = (currentSlideWidth: string) => keyframes`
	0% { transform: translateX(calc(-${currentSlideWidth} * .3)); }
	100% { transform: translateX(calc(-${currentSlideWidth} * 4.3))}
`;
export const Slider = styled.div`
  flex: 350px;
  position: relative;
  overflow: hidden;
  margin: 0;
`;

export const ImgWrap = styled.div`
  position: relative;
  width: ${mobileSlideWidth};
  height: ${mobileSlideWidth};

  ${media.md.up} {
    width: ${desktopSlideWidth};
    height: ${desktopSlideWidth};
  }

  &:nth-child(4n + 1) {
    filter: none;
    transform: rotate(-7deg);
  }

  &:nth-child(4n + 3) {
    transform: rotate(8deg);
  }
`;

export const Img = styled(Image)`
  filter: grayscale(100%);
  width: ${mobileSlideWidth};

  ${media.md.up} {
    width: ${desktopSlideWidth};
  }
`;

export const ImageContainer = styled.div<{ number?: number }>`
  display: flex;
  height: 250px;
  overflow: visible;
  margin-bottom: -250px;
  padding-bottom: 30px;
  padding-top: 30px;
  animation: ${infiniteAutoplayAnimation(mobileSlideWidth)} ${animationSpeed}
    linear infinite;
  max-width: calc(${mobileSlideWidth} * ${({ number }) => number || 12});
  width: calc(${mobileSlideWidth} * ${({ number }) => number || 12});

  ${media.md.up} {
    height: 350px;
    animation: ${infiniteAutoplayAnimation(desktopSlideWidth)} ${animationSpeed}
      linear infinite;
    max-width: calc(${desktopSlideWidth} * ${({ number }) => number || 12});
    width: calc(${desktopSlideWidth} * ${({ number }) => number || 12});
  }

  span {
    overflow: visible !important;
  }
`;

export const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  background: ${({ theme }) => theme.colors.black};
`;
