import React from "react";
import { useIntl } from "react-intl";

import { Container } from "@components/Container";
import { useIsMdUp } from "@hooks/responsive";
import { commonMessages } from "@messages/common";
import { duplicateArray } from "@utils";

import * as S from "./styles";

type SliderProps = {
  photos: string[];
};

export const Slider = ({ photos }: SliderProps) => {
  const { formatMessage } = useIntl();
  const isDesktop = useIsMdUp();
  const mappedPhotos = duplicateArray(photos, 3).map((photo, i) => (
    <S.ImgWrap key={i}>
      <S.Img
        alt={`${formatMessage(commonMessages.roughTradeDescription)}-${i}`}
        height={isDesktop ? 300 : 250}
        layout="responsive"
        objectFit="cover"
        sizes="(max-width: 767px) 50vw, 25vw"
        src={photo}
        width={isDesktop ? 300 : 250}
      />
    </S.ImgWrap>
  ));

  return (
    <S.Slider aria-hidden>
      <S.Background />
      <Container>
        <S.ImageContainer number={photos.length * 3}>
          {mappedPhotos}
        </S.ImageContainer>
      </Container>
    </S.Slider>
  );
};
