import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React from "react";

import { SliceVariants } from "@components/SliceVariants";

import { components } from "./variants";

/**
 * Props for `Teaser`.
 */
export type TeaserProps = SliceComponentProps<Content.TeaserSlice>;

/**
 * Component for "Teaser" Slices.
 */
const Teaser = ({ slice, ...config }: TeaserProps): JSX.Element => (
  <SliceVariants {...config} components={components} slice={slice} />
);

export default Teaser;
